import {ThemeProvider} from '@mui/material/styles';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard';
import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {theme} from './theme';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from './firebase';
import Loader from './components/Loader/Loader';
import {ToastContainer} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import Register from './components/Auth/Register';
import Error404 from './components/Error404';

const App = () => {
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  if (loading) return <Loader />;
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        {user ? (
          <>
            <Dashboard user={user} />
          </>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route component={Error404} />
          </Switch>
        )}
      </ThemeProvider>
    </Router>
  );
};

export default App;
