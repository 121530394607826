/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import { makeStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
import youtubeIcon from '../../assets/social-icons/youtube.svg';
import metaIcon from '../../assets/social-icons/meta.svg';
import tikTokIcon from '../../assets/social-icons/tik-tok.svg';
import twitchIcon from '../../assets/social-icons/twitch.svg';
import patreonIcon from '../../assets/social-icons/patreon.svg';
import robloxIcon from '../../assets/social-icons/roblox.svg';
import substackIcon from '../../assets/social-icons/substack.svg';
import shopifyIcon from '../../assets/social-icons/shopify.svg';
import twitterIcon from '../../assets/social-icons/twitter.svg';
import discordIcon from '../../assets/social-icons/discord.svg';
import mediumIcon from '../../assets/social-icons/medium.svg';
import bannerBackground from '../../assets/banner-background.png';
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { auth, db } from "../../firebase";
import firebase from "../../firebase";
import axios from "axios";
import checkIcon from '../../assets/check.svg';
import addIcon from '../../assets/add-icon.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100%',
    },
    upper: {
        height: '150px',
        color: theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    platforms: {
        height: 'calc(100vh - 300px)',
        overflowY: 'scroll'
    },
    platformRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '25px 0px',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
            margin: '0px'
        }
    },
    platformOption: {
        width: '200px',
        display: 'flex',
        height: '100px',
        cursor: 'pointer',
        background: theme.palette.white.main,
        boxShadow: '0px 4px 8px rgb(0 0 0 / 25%)',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: '0 10px',
        [theme.breakpoints.between('xs', 'sm')]: {
            marginBottom: '25px',
            width: 'calc(100% - 40px)'
        }
    },
    platformIcon: {
        height: '30px',
        width: '30px'
    },
    platformTitle: {
        color: '#938F8F'
    },
    platformState: {
        height: '30px',
        width: '30px'
    },
    lower: {
        display: 'flex',
        justifyContent: 'center',
        height: '150px'
    },
    title: {
        fontSize: '20px',
    },
    subTitle: {
        fontSize: '16px',
        paddingTop: '20px'
    },
    primaryButton: {
        background: `url(${bannerBackground})`,
        borderRadius: '80px',
        color: 'white',
        backgroundSize: 'cover',
        height: '50px',
        padding: '15px 20px',
        backgroundPosition: 'center',
        border: '0px',
        cursor: 'pointer',
    },
    logoutButton: {
        fontSize: '13px',
        position: 'absolute',
        color: theme.palette.primary.main,
        top: '32px',
        right: '32px',
        cursor: 'pointer'
    }
}));

const Platform = ({ user }) => {
    const [userData, setUser] = useState(user);
    const getData = () => {
        db.collection(`users`)
            .doc(auth.currentUser.uid)
            .get()
            .then((value) => {
                setUser(value.data());
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function HandleOnLinkYoutube(account) {
        gapi.load("client:auth2", function () {
            gapi.auth2.init({
                client_id:
                    "978229505366-dni7k93lmn88mhkqjkve88qtqobktg9j.apps.googleusercontent.com",
                project_id: "falcon-336516",
                auth_uri: "https://accounts.google.com/o/oauth2/auth",
                token_uri: "https://oauth2.googleapis.com/token",
                auth_provider_x509_cert_url:
                    "https://www.googleapis.com/oauth2/v1/certs",
                redirect_uris: ["https://oauth2.alchemic.ca", "http://localhost:3000"],
                javascript_origins: [
                    "https://oauth2.alchemic.ca",
                    "https://alchemic.ca",
                    "http://localhost:3000",
                ],
                access_type: "offline",
            });
            gapi.auth2
                .getAuthInstance()
                .grantOfflineAccess({
                    prompt: "consent",
                    // approval_prompt : 'force',
                    scope:
                        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.download https://www.googleapis.com/auth/yt-analytics-monetary.readonly https://www.googleapis.com/auth/youtube.readonly",
                })
                .then(
                    async function (res) {
                        axios({
                            method: "post",
                            url: "https://us-central1-falcon-336516.cloudfunctions.net/app/generate_token",
                            headers: {
                                authorization:
                                    "Bearer " + (await firebase.auth().currentUser.getIdToken()),
                                "content-type": "application/json",
                            },
                            data: {
                                code: res.code,
                                redirectUri: window.location.origin,
                            },
                        });
                        let objectToUpdate = {
                            isYoutubeLinked: true,
                        };
                        if (account) {
                            objectToUpdate = {};
                            objectToUpdate[account] = true;
                            userData[account] = true;
                        }
                        else {
                            userData.isYoutubeLinked = true;
                        }
                        db.collection(`users`)
                            .doc(auth.currentUser.uid)
                            .update(objectToUpdate)
                            .then(() => {
                                db.collection(`users`)
                                    .doc(auth.currentUser.uid)
                                    .get()
                                    .then((value) => {
                                        setUser(value.data());
                                    });
                            });
                    },
                    function (err) {
                        console.error("Error signing in", err);
                    }
                );
        });
    }
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.upper}>
                <div className={classes.title}>Connect your creator platforms</div>
                <div className={classes.subTitle}>We look at your platform analytics and give you offers within 6 hours!</div>
            </div>
            <div className={classes.platforms}>
                <div className={classes.platformRow}>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isYoutubeLinked) HandleOnLinkYoutube()
                        }}
                    >
                        <img className={classes.platformIcon} src={youtubeIcon} alt='YouTube' />
                        <div className={classes.platformTitle} >
                            YouTube
                        </div>
                        <img className={classes.platformState} src={userData.isYoutubeLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isMetaLinked) {
                                HandleOnLinkYoutube('isMetaLinked')
                            }
                        }}
                    >
                        <img className={classes.platformIcon} src={metaIcon} alt='Meta' />
                        <div className={classes.platformTitle} >
                            Meta
                        </div>
                        <img className={classes.platformState} src={userData.isMetaLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isTikTokLinked) HandleOnLinkYoutube('isTikTokLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={tikTokIcon} alt='Tik-Tok' />
                        <div className={classes.platformTitle} >
                            TikTok
                        </div>
                        <img className={classes.platformState} src={userData.isTikTokLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                </div>
                <div className={classes.platformRow}>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isTwitchLinked) HandleOnLinkYoutube('isTwitchLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={twitchIcon} alt='Twitch' />
                        <div className={classes.platformTitle} >
                            Twitch
                        </div>
                        <img className={classes.platformState} src={userData.isTwitchLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isPatreonLinked) HandleOnLinkYoutube('isPatreonLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={patreonIcon} alt='Patreon' />
                        <div className={classes.platformTitle} >
                            Patreon
                        </div>
                        <img className={classes.platformState} src={userData.isPatreonLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isRobloxLinked) HandleOnLinkYoutube('isRobloxLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={robloxIcon} alt='Roblox' />
                        <div className={classes.platformTitle} >
                            Roblox
                        </div>
                        <img className={classes.platformState} src={userData.isRobloxLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isSubstackLinked) HandleOnLinkYoutube('isSubstackLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={substackIcon} alt='Substack' />
                        <div className={classes.platformTitle} >
                            Substack
                        </div>
                        <img className={classes.platformState} src={userData.isSubstackLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>

                </div>
                <div className={classes.platformRow}>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isShopifyLinked) HandleOnLinkYoutube('isShopifyLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={shopifyIcon} alt='Shopify' />
                        <div className={classes.platformTitle} >
                            Shopify
                        </div>
                        <img className={classes.platformState} src={userData.isShopifyLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isTwitterLinked) HandleOnLinkYoutube('isTwitterLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={twitterIcon} alt='Twitter' />
                        <div className={classes.platformTitle} >
                            Twitter
                        </div>
                        <img className={classes.platformState} src={userData.isTwitterLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isDiscordLinked) HandleOnLinkYoutube('isDiscordLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={discordIcon} alt='Discord' />
                        <div className={classes.platformTitle} >
                            Discord
                        </div>
                        <img className={classes.platformState} src={userData.isDiscordLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                    <div className={classes.platformOption}
                        onClick={() => {
                            if (!userData.isMediumLinked) HandleOnLinkYoutube('isMediumLinked')
                        }}
                    >
                        <img className={classes.platformIcon} src={mediumIcon} alt='Medium' />
                        <div className={classes.platformTitle} >
                            Medium
                        </div>
                        <img className={classes.platformState} src={userData.isMediumLinked ? checkIcon : addIcon} alt='Add Icon' />
                    </div>
                </div>
            </div>
            <div className={classes.lower}>
                <button className={classes.primaryButton}
                    onClick={() => {
                        window.location.href = '/dashboard'
                    }}
                >Go to Dashboard</button>
            </div>
            <span className={classes.logoutButton} onClick={() => {
                auth.signOut();
                window.location.href = '/'
            }}>Logout</span>
        </div>
    );
};

export default Platform;
