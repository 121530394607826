import {Box} from '@mui/system';
import React from 'react';
import error404 from '../../assets/Error404.png';

const Error404 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <img src={error404} alt="Error 404" />
    </Box>
  );
};

export default Error404;
