/* eslint-disable no-dupe-keys */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { TextField, Typography } from '@mui/material';
import { auth, db } from '../../firebase';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import firebase from 'firebase';
import googleLogo from '../../assets/googleLogo.png';
import appLogo from '../../assets/logo.png';
import bannerBackground from '../../assets/banner-background.png';
import rocketImage from '../../assets/rocket.png';


const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        margin: 'auto',
        height: '100vh',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '94%',
        },
    },
    leftBanner: {
        width: '60%',
        height: '100%',
        backgroundImage: `url(${bannerBackground})`,
        backgroundSize: 'cover',
        [theme.breakpoints.down('md')]: {
            backgroundColor: theme.palette.secondary.main,
            display: 'none',
        },
    },
    leftInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 3em',
        height: '100%'
    },
    rightFormArea: {
        width: '40%',
        [theme.breakpoints.between('xs', 'md')]: {
            width: '100%'
        }
    },
    rightFormForm: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 3em',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        }
    },
    rightInnerFormForm: {
        padding: '5em 0px'
    },
    rocketImage: {
        maxHeight: '325px'
    },
    caption: {
        fontSize: '60px',
        fontWeight: '700',
        color: theme.palette.white.main
    },
    formAppLogo: { height: '80px' },
    textField: {
        margin: '10px',
        width: '100%',
        color: '#9f4872',
    },
    field: {
        width: '100%',
        margin: '10px 0px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formButton: {
        textTransform: 'none !important',
        boxShadow: '0px 0px 2.41919px rgb(0 0 0 / 8%), 0px 2.41919px 2.41919px rgb(0 0 0 / 17%) !important',
        borderRadius: '4.83838px !important',
        width: '150px !important',
        height: '40px !important',
        border: '0 !important',
        background: 'white !important',
        color: '#5050508A !important',
        textTransform: 'none !important'
    },
    googleButton: {
        textTransform: 'none !important',
        color: '#5050508A !important',
        boxShadow: '0px 0px 2.41919px rgb(0 0 0 / 8%), 0px 2.41919px 2.41919px rgb(0 0 0 / 17%) !important',
        borderRadius: '4.83838px !important',
        width: '300px',
        height: '40px',
        border: '0 !important'
    },
    footer: {
        height: '150px',
        justifyContent: 'center',
        margin: '0px',
        padding: '20px 0px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    copyRight: {
        fontSize: 12,
        color: '#AAA4A4'
    },
    spacer: {
        height: '10px'
    },
    createAccount: {
        fontSize: '13px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    usefulLinksContainer: {
        display: 'flex',
        height: '50px',
        width: '100%',
        fontSize: '14px',
        alignItems: 'flex-end',
        justifyContent: 'space-around',
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
}));

const Login = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const signIn = async () => {
        try {
            // check user auth.
            await auth.signInWithEmailAndPassword(email, password);
            window.location.href = '/platform'
        } catch (err) {
            toast.error(err.code);
        }
    };

    // auth methods
    const signInWithGoogle = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({
                // Force re-consent.
                prompt: 'consent',
            });

            const user = await auth.signInWithPopup(provider);
            const userRef = db.collection(`users/`).doc(`${auth.currentUser.uid}`);
            if (!(await userRef.get()).exists) {
                const email = auth.currentUser.email;
                await userRef
                    .set({
                        email,
                        name: user.user.displayName,
                        isYoutubeLinked: false,
                    })
                    .then(done => console.log('done', done));
            }
            window.location.href = '/platform'
        } catch (err) {
            toast.error(err.code);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.leftBanner}>
                <div className={classes.leftInnerContainer}>
                    <div className={classes.caption}>Growth capital and insights for creators</div>
                    <img src={rocketImage} className={classes.rocketImage} alt='rocket' />
                </div>

            </div>
            <div className={classes.rightFormArea}>
                <form noValidate className={classes.rightFormForm} autoComplete="off">
                    <div className={classes.rightInnerFormForm}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <div className={classes.spacer}></div>
                            <div className={classes.spacer}></div>
                            <img src={appLogo} alt="appLogo" className={classes.formAppLogo} />
                            <Typography variant="h5">Log in</Typography>
                        </Box>
                        <div className={classes.spacer}></div>
                        <div className={classes.spacer}></div>
                        <div className={classes.spacer}></div>
                        <div className={classes.field}>
                            <TextField
                                id="outlined-flexible"
                                label="Email"
                                variant="outlined"
                                type="email"
                                name="email"
                                className={classes.textField}
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className={classes.field}>
                            <TextField
                                id="outlined-flexible"
                                label="Password"
                                variant="outlined"
                                type="password"
                                name="password"
                                className={classes.textField}
                                value={password}
                                required
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className={classes.spacer}></div>
                        <div className={classes.spacer}></div>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                type="button"
                                fullWidth
                                onClick={signIn}
                                className={classes.formButton}
                            >
                                Sign In
                            </Button>
                            <Button
                                sx={{ mt: 2 }}
                                variant="outlined"
                                startIcon={
                                    <img
                                        src={googleLogo}
                                        alt="Google"
                                        width="20px"
                                        height="20px"
                                    />
                                }
                                className={[classes.googleButton]}
                                onClick={signInWithGoogle}
                            >
                                <span>Sign in with Google</span>
                            </Button>
                        </div>
                        <div className={classes.spacer}></div>
                        <div className={classes.spacer}></div>
                        <div
                            className={classes.createAccount}
                            onClick={() => history.push('/register')}
                        >
                            Don't have an account
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <span className={classes.copyRight}>Copyright Alchemic incorporated as 13471357 Canada Inc, 2022
                        </span>
                        <div className={classes.usefulLinksContainer}>
                            <div className={classes.usefulLinksContainer} onClick={() => { window.open('mailto:ali@alchemic.ca'); }}>
                                Contact us
                            </div>
                            <div className={classes.usefulLinksContainer} onClick={() => { window.open('https://www.alchemic.ca/privacy-policy'); }}>
                                Privacy policy
                            </div>
                            <div className={classes.usefulLinksContainer} onClick={() => { window.open('https://www.alchemic.ca/terms-of-services'); }}>
                                Terms of service
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
