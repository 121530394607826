import { makeStyles } from "@mui/styles";
import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SideBar from '../SideBar/index';
import DashboardHome from "./DashboardHome";
import Platform from "./platform";
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: '100vh',
    },
    mainArea: {
        width: '85%',
        [theme.breakpoints.between('xs', 'md')]: {
            width: '100%'
        }
    },
}));

const Dashboard = ({ user }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <SideBar />
            <div className={classes.mainArea}>
                <Switch>
                    <Route exact path="/" render={props => <DashboardHome {...props} user={user} />} />
                    <Route exact path="/dashboard" render={props => <DashboardHome {...props} user={user} />} />
                    <Route exact path="/platform" render={props => <Platform {...props} user={user} />} />
                </Switch>
            </div>
        </div>
    );
};

export default Dashboard;
