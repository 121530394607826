import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDeUiOkCoe6DDP-uWJnb2r8UlIDk1v_zDM",
  authDomain: "oauth2.alchemic.ca",
  databaseURL: "https://falcon-336516-default-rtdb.firebaseio.com",
  projectId: "falcon-336516",
  storageBucket: "falcon-336516.appspot.com",
  messagingSenderId: "978229505366",
  appId: "1:978229505366:web:fdd7b0031053bb8637baf7",
  measurementId: "G-7SWHKYMT7X"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export default firebase;
