/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import { makeStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/system";
import { auth, db } from "../../firebase";
import firebase from "../../firebase";
import checkIcon from '../../assets/check.svg';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import bannerBackground from '../../assets/banner-background.png';


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100%',
    },
    upper: {
        height: '150px',
        color: theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    lower: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontSize: '30px'
    },
    customTable: {
        height: 'calc(100% - 50px)',
        overflowY: 'scroll',
    },
    primaryButton: {
        background: `url(${bannerBackground})`,
        borderRadius: '80px',
        color: 'white',
        backgroundSize: 'cover',
        height: '50px',
        padding: '15px 20px',
        backgroundPosition: 'center',
        border: '0px',
        cursor: 'pointer'
    },
    textField: {
        background: theme.palette.white.main,
        border: '1px #baacac solid',
        borderRadius: '15px',
        height: '40px',
        width: '300px',
        padding: '0px 10px'
    },
    logoutButton: {
        fontSize: '13px',
        position: 'absolute',
        color: theme.palette.primary.main,
        top: '32px',
        right: '32px',
        cursor: 'pointer'
    }
}));

const DashboardHome = ({ user }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [amount, setAmount] = useState(null);
    const [userData, setUser] = useState(user);
    const amountRef = db.collection(`users/${auth.currentUser.uid}/funds`);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp;

    const getData = () => {
        db.collection(`users/${auth.currentUser.uid}/funds`).onSnapshot(
            (querySnapShot) => {
                const payload = querySnapShot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                // sort the notes by createdAt
                payload.sort((first, second) => {
                    return second.createdAt - first.createdAt;
                });
                setRows([...payload]);
            }
        );
        db.collection(`users`)
            .doc(auth.currentUser.uid)
            .get()
            .then((value) => {
                setUser(value.data());
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addAmountHandler = async () => {
        if (!amount) {
            toast.error("Please add amount");
        } else {
            setAmount("");
            try {
                await amountRef.add({
                    date: Date(),
                    fundingAmount: `$${amount}`,
                    premium: "Pending",
                    revenueShare: "Pending",
                    status: "Pending",
                    createdAt: timestamp(),
                });
                toast.success("New record added");
            } catch (err) {
                toast.error(err.message);
            }
        }
    };

    return (
        <div className={classes.container}>

            <div className={classes.upper}>
                <div className={classes.title}>Your dashboard</div>
            </div>
            <div style={
                {
                    height: '250px',
                    padding: '8px 32px'
                }
            }>
                <Box sx={{ padding: 2, boxShadow: 3 }} style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    padding: '16px 32px'
                }}>
                    <Typography variant="h5">
                        Funding
                    </Typography>
                    <Typography variant="subtitle1" style={{
                        textAlign: 'center'
                    }}>
                        Please mention your interested funding (it helps us to tailor
                        our offers)
                    </Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <input className={classes.textField} value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="$"></input>

                    </div>
                    <div className={classes.lower}>
                        <button className={classes.primaryButton}
                            onClick={addAmountHandler}
                        >Request Funding</button></div>
                </Box>
            </div>
            <Box sx={{ boxShadow: 3, m: 4, p: 2 }} style={{
                height: 'calc(100vh - 500px)'
            }}>
                <Typography variant="h5" sx={{ px: 2 }}>
                    Funding
                </Typography>

                <TableContainer
                    style={{
                        height: 'calc(100% - 50px)',
                    }}
                >
                    <Table
                        sx={{ minWidth: 650 }}

                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow style={{
                                height: '40px'
                            }}>
                                <TableCell style={{
                                    borderBottom: 'none'
                                }} sx={{ color: "black", fontWeight: "600" }}>
                                    Date
                                </TableCell>
                                <TableCell style={{
                                    borderBottom: 'none'
                                }} sx={{ color: "black", fontWeight: "600" }}>
                                    Funding Amount
                                </TableCell>

                                <TableCell style={{
                                    borderBottom: 'none'
                                }} sx={{ color: "black", fontWeight: "600" }}>
                                    Revenue Share
                                </TableCell>
                                <TableCell style={{
                                    borderBottom: 'none'
                                }} sx={{ color: "black", fontWeight: "600" }}>
                                    Time period
                                </TableCell>
                                <TableCell style={{
                                    borderBottom: 'none'
                                }} sx={{ color: "black", fontWeight: "600" }}>
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((rowData, index) => {
                                return (
                                    <TableRow
                                        style={{
                                            height: '40px'
                                        }}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                        key={index}
                                    >
                                        <TableCell style={{
                                            borderBottom: 'none'
                                        }}>
                                            {moment(rowData.date).format("ddd MMM DD YYYY")}
                                        </TableCell>
                                        <TableCell style={{
                                            borderBottom: 'none'
                                        }}>{rowData.fundingAmount}</TableCell>
                                        <TableCell style={{
                                            borderBottom: 'none'
                                        }}>{rowData.revenueShare}</TableCell>
                                        <TableCell style={{
                                            borderBottom: 'none'
                                        }}>{rowData.premium}</TableCell>
                                        <TableCell style={{
                                            borderBottom: 'none',
                                            display: 'flex',

                                        }}>{rowData.status} <div style={{
                                            display: rowData.status === 'Approved' ? 'block' : 'none',
                                            marginLeft: '3px'
                                        }}> <img src={checkIcon} alt='check' style={{
                                            height: '20px'
                                        }} />
                                            </div></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <span className={classes.logoutButton} onClick={() => {
                auth.signOut();
                window.location.href = '/'
            }}>Logout</span>
        </div >
    );
};

export default DashboardHome;
