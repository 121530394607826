import { makeStyles } from "@mui/styles";
import React from 'react'
import appLogo from '../../assets/logo.png';
import bannerBackground from '../../assets/banner-background.png';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '20%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundImage: `url(${bannerBackground})`,
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none'
        }
    },
    innerContainer: {
        padding: '40px 30px',
    },
    sideBarRow: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.icon.inactive,
        fill: theme.palette.icon.inactive,
        marginTop: '20px',
        cursor: 'pointer'
    },
    sideBarRowIcon: {
        height: '25px',
        width: '25px',
        fill: 'inherit'
    },
    sideBarRowActive: {
        color: theme.palette.icon.active,
        fill: theme.palette.icon.active,
    },
    sideBarRowLabel: {
        fontSize: '15px',

        marginLeft: '20px',
    },
    appLogo: {
        width: 'calc(100%)',
        marginBottom: '20px',
    },
    mainArea: {

    },
    logoutButton: {
        fontSize: '13px',
        position: 'absolute',
        color: 'white',
        bottom: '20px',
        cursor: 'pointer'
    }
}));
const SideBar = (fullScreen) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <div>
                    <img src={appLogo} alt="appLogo" className={classes.appLogo} />
                </div>
                <div
                    onClick={() => {
                        window.location.href = '/dashboard'
                    }}
                    className={`${classes.sideBarRow} ${window.location.pathname === '/' || window.location.pathname === '/dashboard' ? classes.sideBarRowActive : classes.sideBarRow}`}>
                    <svg width="25" height="25" className={classes.sideBarRowIcon} viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd" d="M8.75 6.57709H26.25C29.4717 6.57709 32.0833 9.18876 32.0833 12.4104V18.6229C32.0833 21.8446 29.4717 24.4563 26.25 24.4563H8.75C5.52834 24.4563 2.91667 21.8446 2.91667 18.6229V12.4104C2.91667 9.18876 5.52834 6.57709 8.75 6.57709ZM26.25 22.0208C28.1266 22.0208 29.6479 20.4995 29.6479 18.6229V12.4104C29.6479 10.5338 28.1266 9.0125 26.25 9.0125H8.75C6.87339 9.0125 5.35209 10.5338 5.35209 12.4104V18.6229C5.35209 20.4995 6.87339 22.0208 8.75 22.0208H26.25Z" />
                        <path d="M25.5208 27.3292H9.47917C8.87511 27.3292 8.38542 27.8189 8.38542 28.4229C8.38542 29.027 8.87511 29.5167 9.47917 29.5167H25.5208C26.1249 29.5167 26.6146 29.027 26.6146 28.4229C26.6146 27.8189 26.1249 27.3292 25.5208 27.3292Z" />
                    </svg>
                    <span className={classes.sideBarRowLabel} >Dashboard</span>
                </div>
                <div
                    onClick={() => {
                        window.location.href = '/platform'
                    }}
                    className={`${classes.sideBarRow} ${window.location.pathname.includes('platform') ? classes.sideBarRowActive : classes.sideBarRow}`}>
                    <svg width="24" height="28" viewBox="0 0 35 39" className={classes.sideBarRowIcon} style={{
                        height: '28px'
                    }} xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_2_181)">
                            <path clip-rule="evenodd" d="M7.65622 4.375H12.1625C13.0352 4.37499 13.8721 4.72269 14.4878 5.34121C15.1036 5.95972 15.4476 6.79807 15.4437 7.67083V12.1625C15.4437 13.9747 13.9747 15.4438 12.1625 15.4438H7.65622C5.84404 15.4438 4.37497 13.9747 4.37497 12.1625V7.67083C4.37109 6.79807 4.71508 5.95972 5.33086 5.34121C5.94663 4.72269 6.78345 4.37499 7.65622 4.375ZM12.1625 13.2417C12.7633 13.2338 13.2484 12.7487 13.2562 12.1479V7.67083C13.2562 7.06677 12.7665 6.57708 12.1625 6.57708H7.65623C7.05217 6.57708 6.56248 7.06677 6.56248 7.67083V12.1479C6.57034 12.7487 7.05545 13.2338 7.65623 13.2417H12.1625Z" />
                            <path clip-rule="evenodd" d="M22.8375 4.375H27.3438C28.2165 4.37499 29.0534 4.72269 29.6691 5.34121C30.2849 5.95972 30.6289 6.79807 30.625 7.67083V12.1625C30.625 13.9747 29.1559 15.4438 27.3438 15.4438H22.8375C21.0253 15.4438 19.5563 13.9747 19.5563 12.1625V7.65625C19.5563 5.84407 21.0253 4.375 22.8375 4.375ZM27.3438 13.2417C27.9445 13.2338 28.4297 12.7487 28.4375 12.1479V7.67083C28.4375 7.06677 27.9478 6.57708 27.3438 6.57708H22.8375C22.2334 6.57708 21.7438 7.06677 21.7438 7.67083V12.1479C21.7516 12.7487 22.2367 13.2338 22.8375 13.2417H27.3438Z" />
                            <path clip-rule="evenodd" d="M7.65622 19.5562H12.1625C13.9747 19.5562 15.4437 21.0253 15.4437 22.8375V27.3437C15.4437 29.1559 13.9747 30.625 12.1625 30.625H7.65622C6.78345 30.625 5.94663 30.2773 5.33086 29.6588C4.71508 29.0403 4.37109 28.2019 4.37497 27.3292V22.8375C4.37497 21.0253 5.84404 19.5562 7.65622 19.5562ZM12.1625 28.4229C12.7665 28.4229 13.2562 27.9332 13.2562 27.3292V22.8375C13.2484 22.2367 12.7633 21.7516 12.1625 21.7437H7.65623C7.05545 21.7516 6.57034 22.2367 6.56248 22.8375V27.3292C6.56248 27.9332 7.05217 28.4229 7.65623 28.4229H12.1625Z" />
                            <path clip-rule="evenodd" d="M22.8375 19.5562H27.3437C29.1559 19.5562 30.625 21.0253 30.625 22.8375V27.3292C30.6288 28.2019 30.2849 29.0403 29.6691 29.6588C29.0533 30.2773 28.2165 30.625 27.3437 30.625H22.8375C21.9647 30.625 21.1279 30.2773 20.5121 29.6588C19.8963 29.0403 19.5523 28.2019 19.5562 27.3292V22.8375C19.5562 21.0253 21.0253 19.5562 22.8375 19.5562ZM27.3437 28.4229C27.9478 28.4229 28.4375 27.9332 28.4375 27.3292V22.8375C28.4296 22.2367 27.9445 21.7516 27.3437 21.7437H22.8375C22.2367 21.7516 21.7516 22.2367 21.7437 22.8375V27.3292C21.7437 27.9332 22.2334 28.4229 22.8375 28.4229H27.3437Z" />
                        </g>
                        <defs>
                            <filter id="filter0_d_2_181" x="-4" y="0" width="43" height="43" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_181" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_181" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    <span className={classes.sideBarRowLabel} >Platforms</span>
                </div>
            </div>
        </div>
    )
}

export default SideBar
