import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#767171",
      main: "#A739B0",
      dark: "#A739B0",
      contrastText: "#fff",
    },
    grey: {
      50: "#2a2b2c",
    },
    secondary: {
      main: "#FA00FF"
    },
    icon:{
      inactive:'#B4CBE0',
      active:'#FFFFFF'
    },
    black: {
      main: '#151419',
      contrastText: "#fff"
    },
    violet: {
      main: '#6441a2',
      contrastText: "#fff"
    },
    white: {
      main: '#FFFFFF',
      contrastText: "#000000"
    }
  },
});

export { theme };
